import {TableBase} from "../../tables/table_base";

class InfringementsPerShift extends TableBase {
  constructor() {
    super('infringements_per_shift');
  }

  paintTable($el, data) {
    $el.find('#infringements_this_year').text(data.this_year.infringements)
    $el.find('#shifts_this_year').text(data.this_year.shifts)
    $el.find('#infringements_last_year').text(data.last_year.infringements)
    $el.find('#shifts_last_year').text(data.last_year.shifts)

    let percent_this_year, percent_last_year;
    if (data.this_year.shifts > 0) {
      percent_this_year = Number.parseFloat(data.this_year.infringements / data.this_year.shifts) * 100
    } else {
      percent_this_year = 0
    }

    if (data.last_year.shifts > 0) {
      percent_last_year = Number.parseFloat(data.last_year.infringements / data.last_year.shifts) * 100
    } else {
      percent_last_year = 0
    }

    const ratio = percent_this_year - percent_last_year;
    $el.find('#percent_infringements_per_shift').text(`${percent_this_year.toFixed(2)} %`)
    $el.find('#percent_infringements_per_shift_last_year').text(`${percent_last_year.toFixed(2)} %`)
    $el.find('#percent_change')
      .text(ratio === 0 ? '-' : `${ratio.toFixed(2)} %`)
      .addClass(ratio > 0 ? 'red': 'green');
  }
}

$(() => {
  if(app && app.DASHBOARD_CORE) {
    new InfringementsPerShift();
  } else {
    console.warn('DashboardCore not initalized.');
  }
});
