import _ from "lodash";

export class TableBase {
    GRIDSTACK_NAME;

    constructor(gridstackName) {
        if(!gridstackName) {
            console.warn('Unable to setup table class no Gridstack ID provided.');
            return;
        }

        this.GRIDSTACK_NAME = gridstackName;
        app.DASHBOARD_CORE.TABLE_CLASSES[this.GRIDSTACK_NAME] = this;
    }

    setup() {
        if(!app.DASHBOARD_CORE.TABLE_DATA[this.GRIDSTACK_NAME]) {
            return;
        }

        try {
            this.paintTable(app.DOM.content.find(`#${this.GRIDSTACK_NAME} table tbody`), app.DASHBOARD_CORE.TABLE_DATA[this.GRIDSTACK_NAME]);
        } catch(err) {
            console.warn(`Failed to setup table ${this.GRIDSTACK_NAME}.`, err);
        }
    }

    paintTable($el, data) {
        let html = '';
        $.each(data, (k,v) => {
            // start row
            html += '<tr>';
            $.each(v, (kk,vv) => {
                html += `<td>${vv}</td>`;
            });
            // end row
            html += '</tr>';
        });
        // insert/update dom
        $el.html(html);
    }

    update() {
        this.setup();
    }
}
